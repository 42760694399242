import tchat from '@amo/core/assets/images/AMO_Icons/Chat.svg';
import info from "@amo/core/assets/images/icons/info.svg";
import { Question, BulleAide } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import { Field } from 'redux-form';
import _ from "lodash";
import moment from "moment";
import { useState } from "react";

const MotifAvenant = (props) => {
    const { nextQuestion, data, isMAJDec, source, initialValues, isRemiseVigueur, hasRegul, hasFid } = props;

    const isMajeur = (index) => {
        const dateNaissance = _.get(initialValues, `DemandeTarif[ListePersonnes][${index}][DateNaissance]`);
        const birthMoment = moment(dateNaissance, 'DD/MM/YYYY');
        return moment.isMoment(birthMoment) && birthMoment.add(18, 'years') <= moment();
    }

    const idRealisateur = _.get(initialValues, 'DemandeTarif[Police][IdRealisateur]');
    const realisateursFid = [
        544208,
        500145,
        500078,
        500093,
        500201,
        500272,
        513571,
        539651,
        544205,
        550234,
        783963
    ];

    const isRealisateurFid = realisateursFid.includes(idRealisateur);

    const isAFN6Mois = moment(_.get(data, 'DemandeTarif[Police][DateAffaireNouvelleContrat]'), 'DD/MM/YYYY').add(6, 'months') < moment();
    const isDerPiece3Mois = moment(_.get(data, 'DemandeTarif[Police][DateEffetContrat]'), 'DD/MM/YYYY').add(3, 'months') < moment();

    const ListePersonnes0Majeur = isMajeur(0);
    const ListePersonnes1Majeur = isMajeur(1);
    const [blocageMajeurs, setBlocageMajeurs] = useState(false);

    const onClickNext = (motif) => {
        if (!(ListePersonnes0Majeur && ListePersonnes1Majeur) || motif === '5') {
            setBlocageMajeurs(false);
            nextQuestion(300)
        } else {
            setBlocageMajeurs(true);
        }
    };

    const renderRadio = (value, label, disabled = false) => (
        <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
            <Field
                component={Radio}
                type={'radio'}
                name={'DemandeTarif[Police][MotifAvenant]'}
                value={value}
                label={label}
                disabled={disabled}
                onClick={(e) => onClickNext(e.target.value)}
            />
        </div>
    );

    return (
        <Question icon={tchat} title={'Quel est le <b>motif de votre avenant</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                {hasFid ? (
                    renderRadio('29', 'Avenant de fidélisation')
                ) : hasRegul ? (
                    renderRadio('7', 'Régularisation de prime')
                ) : (
                    <>
                        {(isMAJDec && source === 'conseiller') ? <>
                                {renderRadio('8', 'Mise à jour de déclaration')}
                            </> :
                            <>
                                {(!isRemiseVigueur) && (
                                    <>
                                        {renderRadio('1', 'Changement de véhicule')}
                                        {renderRadio('2', 'Changement de garanties')}
                                        {renderRadio('3', "Changement d'adresse")}
                                        {(Number(data?.cylindreeVehicule) <= 50 && source === 'conseiller') && renderRadio('5', "Changement de conducteur")}
                                        {!_.includes(['quad', 'verte'], data?.typeVehicule) && renderRadio('9', "Changement d'usage")}
                                    </>
                                )}

                                {source === 'conseiller' && (
                                    <>
                                        {renderRadio('7', 'Régularisation de prime', !isRealisateurFid)}
                                        {renderRadio('10', 'Modification de contrat')}
                                        {renderRadio('11', 'Correctif d\'avenant', isDerPiece3Mois)}
                                        {renderRadio('8', 'Mise à jour de déclaration', isAFN6Mois)}
                                        {renderRadio('29', 'Avenant de fidélisation', !isRealisateurFid)}
                                    </>
                                )}

                                {isRemiseVigueur && (
                                    <>
                                        {renderRadio('1', 'Changement de véhicule')}
                                        {renderRadio('6', 'Remise en vigueur')}
                                    </>
                                )}
                            </>
                        }
                    </>
                )}
            </div>

            {blocageMajeurs && (
                <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                    <p className="mb-1">Pas de modification possible car le conducteur est devenu majeur.</p>
                    <p className="mb-0">Merci de refaire une affaire nouvelle.</p>
                </div>
            )}
            <BulleAide
                icon={info}
                title={(source !== 'site') ? 'Si vous changez de véhicule et que vous souhaitez changer d\'usage et de garanties, passez par le motif "Changement de véhicule".<br/><br/>' +
                    'Si vous avez plusieurs modifications à faire sur le contrat (changement d’adresse + véhicule par exemple), merci de passer par le motif “Modification de contrat”.' : 'Si vous changez de véhicule et que vous souhaitez changer d\'usage et de garanties, passez par le motif "Changement de véhicule".'
                }
                className={'col-lg-12 col-md-12 mb-1 mt-2'}
            />
            <button
                className={`btn btn-primary btn-arrow mt-4 ${(!_.get(data, 'DemandeTarif[Police][MotifAvenant]') || blocageMajeurs) && 'disabled'}`}
                onClick={() => onClickNext(_.get(data, 'DemandeTarif[Police][MotifAvenant]'))}
            >
                Continuer
            </button>
        </Question>
    );
};

export default MotifAvenant;
