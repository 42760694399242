import { getIconByType } from '@amo/core/utils/functions';
import Franchises from '../Franchises/Franchises';
import Options from '../Options/Options';
import SouscrireContainer from '../Souscrire/SouscrireContainer';
import '../tarif.scss';
import getFormuleChoisie from '../utilsTarif/getFormuleChoisie';
import TableFormule from './TableFormule/TableFormule';
import CodePromoContainer from "../CodePromo/CodePromoContainer.js";
import FranchisesInfo from "../Franchises/FranchisesInfo.jsx";

const TableauGarantiesDesktop = (props) => {
    const { data, vehicule, formules, franchises, apporteur1, infoDTA, infoVI } = props;
    const formuleChoisie = getFormuleChoisie(data, formules);

    return (
        <>
            <div className={'d-none d-lg-block'}>
                <div className={'row bg-grey'}>
                    <div
                        className={`bg-blue rounded-bottom-medium p-5 ${
                            apporteur1.codeBelair === 43397 && 'header-tarif'
                        }  position-relative`}
                    >
                        <p className={'text-white f-32 fw-bold p-5 pt-4 text-start'}>
                            <span className={'d-block mb-4'}>
                                Choisissez votre formule <br />
                                pour assurer votre véhicule
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className={'bg-grey d-none d-sm-block'}>
                <div className={'container'}>
                    <div className="row justify-content-between position-relative">
                        <div className="col-12 d-flex">
                            <div className={'px-4 py-3 bg-white mt-3 rounded position-absolute bloc-vehicule shadow'}>
                                <p className={'f-14 text-dark text-start fw-bold'}>
                                    Votre deux-roues{' '}
                                </p>
                                <div className={'row mt-2'}>
                                    <div className="col-3">
                                        <img
                                            src={getIconByType(data?.typeVehicule)}
                                            alt="Véhicule"
                                            className={'pt-2'}
                                        />
                                    </div>
                                    <div className="col-7 text-start">
                                        <p className={'m-0 fw-bold'}>
                                            {vehicule.brand?.name} {vehicule.cylindree}
                                        </p>
                                        <small>
                                            {vehicule.modele}{' '}
                                            {`${
                                                vehicule.annee_debut_version !== null &&
                                                vehicule.annee_debut_version !== 0
                                                    ? `depuis ${vehicule.annee_debut_version}`
                                                    : ''
                                            } ${
                                                vehicule.annee_fin_version !== null && vehicule.annee_fin_version !== 0
                                                    ? ` jusqu'a ${vehicule.annee_fin_version}`
                                                    : ''
                                            }`}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <TableFormule formuleChoisie={formuleChoisie} {...props} />
                        </div>
                        <div className={'col-12'}>
                            <Options {...props} />

                            { franchises.labelGroupe
                                ? <Franchises {...props} />
                                : (infoVI[0] && infoDTA[0] && (infoVI[0].Disponible !== 'NonDisponible' || infoDTA[0].Disponible !== 'NonDisponible')) &&
                                    <FranchisesInfo {...props}
                                        VI={(infoVI[0] && infoVI[0].Disponible !== 'NonDisponible') ? infoVI[0] : null}
                                        DTA={(infoDTA[0] && infoDTA[0].Disponible !== 'NonDisponible') ? infoDTA[0] : null}
                                    />
                            }

                            <CodePromoContainer {...props} />

                            <SouscrireContainer formuleChoisie={formuleChoisie} {...props} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableauGarantiesDesktop;
