import keys from '@amo/core/assets/images/icons/keys.svg';
import { Question } from '@amo/core/components/containers';
import { Radio } from '@amo/core/components/forms';
import _ from 'lodash';
import { Field } from 'redux-form';

export const UsageNonLivraison = (props) => {
    const { nextQuestion, data, redirectToLivraison, links } = props;

    const handleRedirectToLivraison = () => {
        window.location.href = links['flotte'];
    };
    return (
        <Question icon={keys} title={'Ce véhicule est-il destiné à un <b>usage professionnel</b> lié à une activité de <b>restauration ou de livraison</b>&nbsp;?'}>
            <div className={'row justify-content-center mt-2'}>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field component={Radio} type={'radio'} name={'DemandeTarif[Vehicule][UsageNonLivraison]'} value={'0'} label={'Oui'} />
                </div>
                <div className={'col-lg-4 col-md-6 col-sm-6 col-6 my-2'}>
                    <Field component={Radio} type={'radio'} name={'DemandeTarif[Vehicule][UsageNonLivraison]'} value={'1'} label={'Non'} onClick={() => nextQuestion(300)} />
                </div>
            </div>

            {_.get(data, 'DemandeTarif[Vehicule][UsageNonLivraison]') === '0' && (
                <>
                    {redirectToLivraison ? (
                        <div className="alert alert-warning mt-3 col-lg-8 offset-lg-2" role="alert">
                            Pour vous assurer sur un usage professionnel lié à une activité de restauration ou de livraison nous vous redirigeons vers l’offre dédiée à la livraison..
                        </div>
                    ) : (
                        <div className="alert alert-danger mt-3 col-lg-8 offset-lg-2" role="alert">
                            Nous sommes désolés, vous ne pouvez pas assurer le véhicule avec cet usage.
                        </div>
                    )}
                </>
            )}

            <button className={`btn btn-primary btn-arrow mt-4`} onClick={() => redirectToLivraison ? handleRedirectToLivraison() : nextQuestion()}>
                Continuer
            </button>
        </Question>
    );
};
